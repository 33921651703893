import React, { FC } from 'react';
import MainTemplate from '../components/MainTemplate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { PageProps } from '../App';

const Started: FC<PageProps> = ({ setPage, userData, startExperience }) => {
  const restartHandler = async () => {
    setPage('landing');
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const res2 = await startExperience();
    if ('status' in res2 && res2.status === 200) {
      setPage('started');
    } else {
      setPage('landing');
      throw 'Could not resend';
    }
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={restartHandler}
      >
        Start over
      </Button>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Hello!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: '250px',
            margin: '0 auto 40px',
            lineHeight: '1.4'
          }}
        >
          We&apos;re waiting to get your measurements.
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Haven&apos;t measured yet?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            <Button
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
            >
              Resend
            </Button>
          </form>
          {userData.phone ? (
            <Typography variant="subtitle2">SMS charges may apply</Typography>
          ) : null}
        </Box>
      </Box>
    </MainTemplate>
  );
};

export default Started;
